



















































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































import { Component, Watch } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import { getConfigEnv, getComponent, sleep } from '@/utils/helpers';
import tasqsListModule from '@/store/modules/tasqsListModule';
import TasqJob from '@/interfaces/tasqs/TasqJob';
import DataLoading from '@/lib/mixins/dataLoading';
import TasqMixin from '@/lib/mixins/TasqMixin';
import tasqSignalsModule from '@/store/modules/tasqSignalsModule';
import tasqProductionDataChartModule from '@/store/modules/tasqProductionDataChartModule';
import Multiselect from 'vue-multiselect';
import {
  ALL_TASQS_LIST_ITEM,
  SHOW_ALERT,
  TASQ_OFF_TARGET_TYPE,
  TASQ_WAITING_ON_LIST,
  WELL_CLICK_EVENT,
} from '@/lib/constants';
import accountModule from '@/store/modules/accountModule';
import assetsModule from '@/store/modules/assetsModule';
import tasqActionsModule from '@/store/modules/tasqActionsModule';
import defermentLabelingModule from '@/store/modules/defermentLabelingModule';
import workflowModule from '@/store/modules/workflowModule';
import tasqFeedbackModule from '@/store/modules/tasqFeedbackModule';
import DateRangePicker from 'vue2-daterange-picker';
import 'vue2-daterange-picker/dist/vue2-daterange-picker.css';
import scheduleModule from '@/store/modules/scheduleModule';
import workspaceModule from '@/store/modules/workspaceModule';
import { getNameByEmail } from '@/utils/users';
import { datadogRum } from '@datadog/browser-rum';
import metaDataModule from '@/store/modules/metaDataModule';

@Component({
  components: {
    AppLayout: () => getComponent('common/AppLayout'),
    TasqsVerticalList: () => getComponent('tasqs/TasqsVerticalList'),
    TasqSignal: () => getComponent('tasqs/TasqSignal'),
    TasqProductionDataChart: () => getComponent('tasqs/TasqProductionDataChart'),
    TasqFeedback: () => getComponent('tasqs/TasqFeedbackV4'),
    TasqLoader: () => getComponent('loaders/TasqLoader'),
    TasqItem: () => getComponent('tasqs/TasqItem'),
    manualTasq: () => getComponent('tasqs/TasqManualPopup'),
    Multiselect,
    DateRangePicker,
  },
})
export default class TasqsMobile extends mixins(DataLoading, TasqMixin) {
  tasqOptionsOpen = false;

  showAddManualTasq = false;

  chartsLoading = false;

    addTasqAssetType = '';

  signalChartTime = 14;

  isOnlineApp = true;

   autoCompleteTags: any = [];

  tag: any = '';

  tags: any = [];

  subPredictionList: any = [];

  multiTasqType = '';

  activeTasqTab = 'Feedback';

  loadingTasqs = false;

    showDataLoadingFeedback = false

  showComment = false;

  customSignalRange: any = { startDate: null, endDate: null }

  showActionDialog: boolean = false;

   actionErrors: any = [];

  chartTimes: any[] = this.$getConst('CHART_TIMES');

  get stepper() {
    return tasqFeedbackModule.stepper;
  }

  get enabledPads() {
    return assetsModule.enabledPads;
  }

  get waitingOnOptions() {
    return metaDataModule.waitingOnList;
  }

  addTasqDidSelectAssetTasq() {
  }

  get isBatchResponding() {
	  return tasqsListModule.isBatchResponding;
  }

  batchRespond() {
	  if (this.respondEnabled) {
      //   tasqFeedbackModule.resetAllData();
      //   tasqsListModule.setIsBatchResponding(true);
      //   this.activeTasqTab = 'Feedback';
	  }
  }

  get selectedCustomRange() {
    console.log(this.customSignalRange);
    if (this.customSignalRange.startDate && this.customSignalRange.endDate) {
      return true;
    }
    return false;
  }

  get dateRange() {
    const startDate = new Date();
    const endDate = new Date();
    endDate.setDate(endDate.getDate() + 6);
    return {
      dateRange: { startDate, endDate },
    };
  }

  get respondEnabled() {
	  return tasqsListModule.batchResponseEnabled;
  }

  get selectedEditItemsCount() {
	  return tasqsListModule.checkedTasqs.length;
  }

  get getBatchEditResponseDisabledMsg() {
	  return tasqsListModule.batchResponseDisabledMsg;
  }

  getWells() {
    const responseData: any[] = [];
	  if (this.activeTasq != null) {
		  // @ts-ignore
		  for (let r = 0; r < this.activeTasq.wells.length; r++) {
			  // @ts-ignore
        //   if (this.activeTasq.wells[r].predictionType != "Producing") {

				  // @ts-ignore
				  responseData.push(this.activeTasq.wells[r]);
        //   }
		  }
		  return responseData;
	  }
	  return responseData;
    //   if (this.showAllWells) {
    // 	  // @ts-ignore
    // 	  return this.tasq.wells
    //   } else {

    //   }
  }

  get isEditing() {
	  return tasqsListModule.isEditing;
  }

  getPredictionList() {
    console.log(this.activeTasq?.predictionsList);
    if (this.activeTasq && this.activeTasq.predictionsList && Object.keys(this.activeTasq.predictionsList)) {
      let found = false;
      this.subPredictionList = Object.keys(this.activeTasq.predictionsList).map((data) => {
        const object:any = this.activeTasq?.predictionsList[data];
        if (data === this.activeTasq?.overriddenName) {
          found = true;
        }
        if (data === 'Off-Target RT') {
          data = 'REAL-Time';
        }
        data = this.getOverriddenTasqName(data);
        return { name: data, predictionId: object.PredictionID, color: this.bgColorClass(data) };
      });
      if (!found) {
        this.subPredictionList.push({ name: this.activeTasq.engineerType, predictionId: this.activeTasq.id, color: this.bgColorClass(this.activeTasq.overriddenName) });
      }
    } else {
      this.subPredictionList = [];
    }
  }

  resetTasq() {
    tasqFeedbackModule.resetAllData();
  }

  editTasqs() {
	  if (tasqsListModule.isEditing) {
		  this.closeEditToolbar();
	  } else {
		  tasqsListModule.setActiveTasq('');
		  tasqsListModule.setIsEditing(true);
	  }
  }

  closeEditToolbar() {
	  tasqsListModule.setIsEditing(false);
	  this.activeTasqTab = 'Signals';
	  tasqsListModule.setIsBatchResponding(false);
    tasqsListModule.setIsBatchReassign(false);
	  tasqsListModule.resetChekedTasqs();
    tasqsListModule.setActiveTasq('');
    tasqsListModule.setShowTasqDetailsMobile(false);
    assetsModule.setActiveProducingTasq('');

    this.$router.push({ name: 'Tasqs' });
  }

  get tasqListLevel() {
	  if (this.activeTasq == null) {
		  return 'WELL';
	  }
	  return this.activeTasq?.level;
  }

  get tasqModuleListLevel() {
	  return tasqsListModule.tasqListLevel;
  }

  get isProductionDataLoaded() {
    return tasqProductionDataChartModule.productionData.loaded;
  }

  get currentWellType(): any {
	  return this.$route.query.type;
  }

  get signalDescriptions(): any {
	  return tasqSignalsModule.signalDescriptions;
  }

  get currentSignals(): any {
    if (this.chartsLoading) {
      return [];
    }
    const allSignals = tasqSignalsModule.currentSignals;

    if (this.tasqListLevel.toLowerCase() == 'pad') {
      return allSignals.filter((signal) => signal.name !== 'Compressor');
    }
    return allSignals.filter((signal) => signal.wellName === this.activeTasq?.wellName).sort((a, b) =>
    // @ts-ignore
      (a.id === null) - (b.id === null) || +(a.id > b.id) || -(a.id < b.id));
  }

  get activeBatchFeedbackTasq() {
	  if (tasqsListModule.checkedTasqs.length > 0) {
		  return tasqsListModule.tasqById(tasqsListModule.checkedTasqs[0]);
	  }
	  return null;
  }

  get activeTasq() {
    if (this.isEditing || tasqsListModule.checkedTasqs.length) {
      if (!tasqsListModule.activeTasq) {
        assetsModule.setActiveProducingTasq('');
        tasqsListModule.setActiveTasq(tasqsListModule.checkedTasqs[0]);
      }
	  }

    if (this.$route.query.type == 'id' && this.$route.params.id != null) {
      assetsModule.setActiveProducingTasq('');
      return tasqsListModule.activeTasq;
    }
    // @ts-ignore
    if (tasqsListModule.activeTasq != null && this.$route.params.id != null && this.$route.query.type == 'producing' && tasqsListModule.activeTasq.level == 'PAD') {
      return tasqsListModule.activeTasq;
    }
    if (tasqsListModule.activeTasq) {
      assetsModule.setActiveProducingTasq('');
      return tasqsListModule.activeTasq;
    }
    return assetsModule.activeTasq;
  }

  get showTasqDetailsMobile() {
    return tasqsListModule.showTasqDetailsMobile;
  }

  get reassignUsers() {
    return accountModule.reassignmentList.map((i) => ({
      // @ts-ignore
      text: i.name,
      value: i.email,
    })).sort((a, b) => a.text.localeCompare(b.text));
  }

  get enabledWells() {
    return assetsModule.enabledWells;
  }

  setActiveTasqDetailTab(tabName) {
    this.activeTasqTab = tabName;
  }

  async snoozeTasqForToday() {
    const tasqID = this.activeTasq?.id;
    this.startDataLoading();
    const action = {
      reason: 'Snoozed',
      comment: '',
      date: new Date().setDate(new Date().getDate() + 1),
      id: this.activeTasq?.id,
      startDate: new Date(),
    };
    const successText = 'Successfully snoozed!';
    this.snoozeTasqPopup = false;

    await tasqActionsModule.updateTasqStatus(action);
    this.$router.push({ name: 'Tasqs' });
    await sleep(2000);
    await tasqsListModule.getRawTasq({ PredictionID: tasqID });

    this.stopDataLoading();
    if (successText) {
      this.$eventBus.$emit(SHOW_ALERT, successText);
    }
  }

  clearSelectedTasq() {
    tasqsListModule.setActiveTasq('');
    tasqsListModule.setShowTasqDetailsMobile(false);
    assetsModule.setActiveProducingTasq('');
    if (this.isBatchResponding) {
      this.closeEditToolbar();
    }

    this.$router.push({ name: 'Tasqs' });
  }

  async updateTasqPrediction(data) {
    // console.log(data)
    await tasqsListModule.getTasq({
      PredictionID: data.predictionId,
    });
    this.multiTasqType = data.name;
    workflowModule.getJobResponse(data?.predictionId);
  }

  fetchActions() {
    let forms: any = metaDataModule.formBuilderData;
    forms = forms.reduce((r, a) => {
      if (!a.category) {
        a.category = 'None';
      }
      r[a.category] = r[a.category] || [];
      r[a.category].push(a);
      return r;
    }, Object.create(null));
    const actionBuilderActions = Object.keys(forms).map((key) => ({ title: key, actions: forms[key] }));
    tasqFeedbackModule.setAvailableForms(actionBuilderActions);
    // console.log(this.actionBuilderActions);
    // this.dataLoading = false;
  }

  async created() {
    tasqsListModule.setActivePage('Tasq');

    await metaDataModule.getActionsBuilder();

    this.$eventBus.$on(WELL_CLICK_EVENT, (comment) => {
      let actions: any = '';
      if (comment.ResponseData && comment.ResponseData && comment.ResponseData.Actions) {
        actions = this.getCommentActions(comment);
      }

      this.wellEventDetails = {
        action: actions,
        createdBy: getNameByEmail(comment.Username),
        createdDate: comment.time,
        comment: comment.readableComment,
        jobType: comment.JobType,
      };

      this.showWellEventDetail = true;
    });
    //  tasqsListModule.setUsernamesBulkList([])
    //  tasqsListModule.setUsernamesList(accountModule.user.email)

    //  tasqsListModule.setTasqListLevel(listLevel)
    // await tasqsListModule.getTasqsByPagination(ALL_TASQS_LIST_ITEM);
    this.isOnlineApp = navigator.onLine;

    tasqsListModule.setFromNowBack(0);

    this.fetchActions();

    this.sleep(8000).then(() => {
      const promiseList: any = [];
      promiseList.push(assetsModule.getEnabledWells());
      promiseList.push(assetsModule.getEnabledPads());
      promiseList.push(accountModule.getReassignmentList({ useExisting: true }));
      Promise.all(promiseList).then(async () => {
        // console.log('resolved');
        if (this.isOnlineApp) {
          workspaceModule.getWorkspaces(null);
          if (!accountModule.didPreloadData) {
            // await preloadData();
          }

          datadogRum.init({
            applicationId: '42a5cbbe-cee7-4b16-b31c-27f8330f507b',
            clientToken: 'pubb848c6a76f8811fde5cd24dc0264f13f',
            site: 'datadoghq.com',
            service: 'tasq',
            env: getConfigEnv('BRANCH_NAME'),
            // Specify a version number to identify the deployed version of your application in Datadog
            version: accountModule.user.email,
            sampleRate: 100,
            premiumSampleRate: 100,
            trackInteractions: true,
            defaultPrivacyLevel: 'allow',
          });

          datadogRum.startSessionReplayRecording();
        }

        await tasqsListModule.getTasqListResponses();
      }, (err: any) => {
        console.log('Error:');
        console.log(err.message);
      });
    });

    this.loadingTasqs = true;
  }

  async fetchTasqAsync() {
    assetsModule.getEnabledWells();
    if (this.$route.params.id != null && this.$route.params.id != '' && this.$route.query.type == 'id') {
      await this.fetchTasq();
    }
  }

  beforeDestroy() {
    tasqsListModule.setActiveTasq('');
    tasqsListModule.setActivePage('');
  }

  openDialog() {
    this.sleep(300).then(() => {
      this.$dialog.show('actionModal');
    });
  }

  editWaitingOnStatus() {
    if (this.activeTasq && this.activeTasq.waitingOnStartDate && this.activeTasq.waitingOnEndDate) {
    // console.log(this.activeTasq.snoozed.WaitStartDate);
      this.action.type = 'wait';
      this.action.startDate = this.activeTasq?.snoozed.WaitStartDate;
      this.action.date = this.activeTasq?.snoozed.UntilDate;
      if (this.activeTasq.waitUntilReason) {
        this.action.reason = this.activeTasq?.waitUntilReason.split('-')[0];
      }
      // this.action.comment = this.activeTasq.waitUntilReason.split('-')[1];
      this.action.title = `Please select status for ${this.activeTasq?.wellName}:`;
      this.action.confirmText = 'Update';
      this.$dialog.show('actionModal');
    }
  }

  prepareAction(action) {
    // console.log(action);
    this.showActionDialog = true;
    this.action = {
      type: '',
      title: '',
      confirmText: '',
      reason: '',
      person: '',
      comment: '',
      date: '',
      well: '',
      startDate: new Date(),
      jobType: '',
      frequency: 'Today',
      delayedDays: 0,
    };
    switch (action) {
      case ('reject'):
        this.action.type = action;
        this.action.title = `Are you sure you want to reject ${this.activeTasq?.wellName}?`;
        this.action.confirmText = 'Yes, reject it';
        this.$dialog.show('actionModal');
        break;
      case ('reassign'):
        this.action.type = action;
        this.action.title = `Select the user to reassign ${this.activeTasq?.wellName}:`;
        this.action.confirmText = 'Reassign tasq';
        this.$dialog.show('actionModal');
        break;
      case ('wait'):
        // console.log('obj watit');
        this.action.type = action;
        this.action.title = `Please select status for ${this.activeTasq?.wellName}:`;
        this.action.confirmText = 'Update';
        this.$dialog.show('actionModal');
        break;
      case ('add'):
        this.showAddManualTasq = true;
        break;
      default:
        break;
    }
  }

  prepareAddManualAction(action) {
    this.showActionDialog = true;
    this.action = {
      type: '',
      title: '',
      confirmText: '',
      reason: '',
      person: '',
      comment: '',
      date: '',
      well: '',
      jobType: '',
      frequency: 'Today',
      delayedDays: 0,
    };
    switch (action) {
      case ('add'):
        this.action.type = action;
        this.action.title = '';
        this.addTasqAssetType = 'Well';
        this.action.well = this.activeTasq?.wellName;
        this.action.confirmText = 'Add tasq';
        this.action.person = { value: accountModule.user.email, text: `${accountModule.user.firstName} ${accountModule.user.lastName}` };
        this.$dialog.show('actionModal');
        break;
      default:
        break;
    }
  }

  validateDialog(type) {
    if (type === 'reassign') {
      if (!this.action.person) {
        this.actionErrors.push('person');
      }
    } else if (type === 'wait') {
      if (!this.action.date) {
        this.actionErrors.push('date');
      }
      if (!this.action.startDate) {
        this.actionErrors.push('startDate');
      }
      if (!this.action.reason) {
        this.actionErrors.push('reason');
      }

      if (this.action.date && this.action.startDate && this.action.date < this.action.startDate) {
        this.actionErrors.push('invalidEndDate');
      }
    } else if (type === 'reject') {
      if (!this.action.reason) {
        this.actionErrors.push('reason');
      }
    } else if (type === 'add') {
      if (!this.addTasqAssetType) {
        this.actionErrors.push('addTasqAssetType');
      } else {
        if (!this.action.jobType) {
          this.actionErrors.push('jobType');
        }
        if (!this.action.person) {
          this.actionErrors.push('person');
        }
        if (!this.action.well) {
          this.actionErrors.push('well');
        }
      }
    }
  }

  async onActionDialogClosed(response) {
    let successText = ''!;
    this.actionErrors = [];
    const tasqID = this.activeTasq?.id;

    this.startDataLoading();
    console.log(this.action);
    if (response.isOk) {
      this.validateDialog(this.action.type);
      console.log(this.actionErrors);
      if (this.actionErrors.length) {
        this.openDialog();
        return;
      }
      switch (this.action.type) {
        case ('reassign'):
          successText = 'Successfully reassigned!';
          await tasqActionsModule.reassignTasq(this.action);
          tasqsListModule.resetSetActiveTasq();
          break;
        case ('reject'):
          successText = 'Successfully Rejected!';

          await tasqActionsModule.rejectTasq(this.action);
          tasqsListModule.resetSetActiveTasq();
          break;
        case ('wait'):
          successText = 'Successfully added to waitlist!';
          await tasqActionsModule.updateTasqStatus(this.action);
          tasqsListModule.resetSetActiveTasq();
          break;
        case ('add'):
          successText = 'Tasq added successfully!';
		  this.action.assetType = this.addTasqAssetType;
          await tasqActionsModule.addTasq(this.action);
          tasqsListModule.resetSetActiveTasq();
          break;
        default:
          break;
      }

      this.closeEditToolbar();
      this.action = {};
      this.showActionDialog = false;

      this.$router.push({ name: 'Tasqs' });

      await sleep(3000);
      const updatedTasq = await tasqsListModule.getRawTasq({ PredictionID: tasqID });
      console.log(updatedTasq);
      // await tasqsListModule.getTasqs(ALL_TASQS_LIST_ITEM);

      this.stopDataLoading();
      if (successText) {
        this.$eventBus.$emit(SHOW_ALERT, successText);
      }
    }
  }

  sleep(ms) {
    return new Promise((resolve) => setTimeout(resolve, ms));
  }

  async customDateUpdated(data) {
    let fromNowBack = 0;
    const dateNow: any = new Date();
    const date1 = data.startDate;
    const date2 = data.endDate;
    if (Math.ceil(Math.abs(date2)) < Math.ceil(Math.abs(dateNow))) {
      fromNowBack = Math.ceil(Math.abs(date2 - dateNow) / (1000 * 60 * 60 * 24));
    }
    console.log(fromNowBack);
    tasqsListModule.setFromNowBack(fromNowBack);
    const diffTime = Math.abs(date2 - date1);
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
    await this.changeChartTimes(diffDays);
  }

  async changeChartTimes(val) {
    if (!this.selectedCustomRange) {
      tasqsListModule.setFromNowBack(0);
    }
    this.signalChartTime = val;
    tasqsListModule.setSignalChartTime(val);

    tasqProductionDataChartModule.resetProductionData();
    tasqSignalsModule.resetSignals();
    // tasqsListModule.signalChartTime = val;
    this.chartsLoading = true;

    const promises: any[] = [];
    promises.push(tasqSignalsModule.getSignalsForTasq(val));
    promises.push(tasqProductionDataChartModule.getProductionData(val));

    Promise.all(promises).then(async () => {
      this.chartsLoading = false;

      await (tasqProductionDataChartModule.getWellHistory(val));
      await (defermentLabelingModule.getChartLabelingData(this.signalChartTime));
    }, (err) => {
      // error occurred
      this.chartsLoading = false;
    });
  }

  get dateRangeCustomPreset() {
    const today = new Date();
    today.setHours(0, 0, 0, 0);
    return {

      'Last month': [new Date(today.getFullYear(), today.getMonth() - 1, today.getDate()), new Date(today.getFullYear(), today.getMonth(), today.getDate())],
      'Last 3 months': [new Date(today.getFullYear(), today.getMonth() - 3, today.getDate()), new Date(today.getFullYear(), today.getMonth(), today.getDate())],
      'Last 6 months': [new Date(today.getFullYear(), today.getMonth() - 6, today.getDate()), new Date(today.getFullYear(), today.getMonth(), today.getDate())],
      'Last Year': [new Date(today.getFullYear(), today.getMonth() - 12, today.getDate()), new Date(today.getFullYear(), today.getMonth(), today.getDate())],
    };
  }

  async fetchTasq(predictionID = null, loadingFromSubPrediction = false) {
  
    this.wellEventDetails = this.wellEventDetailsDefault;
    this.chartTimes = this.$getConst('CHART_TIMES');

    this.showDataLoadingFeedback = true;
    this.chartsLoading = true;

    tasqsListModule.setTasqEventHistoryList([]);
    workflowModule.setWellHistory([]);

    this.signalChartTime = 14;

    if (!loadingFromSubPrediction) {
      tasqsListModule.setFromNowBack(0);
      tasqSignalsModule.resetSignals();
      tasqProductionDataChartModule.resetProductionData();
      this.customSignalRange = { startDate: null, endDate: null };
    }

    if (this.activeTasq?.engineerType === TASQ_OFF_TARGET_TYPE) {
      let jobOriginExists = false;
      for (let t = 0; t < this.chartTimes.length; t++) {
        if (this.chartTimes[t].text == 'Job Origin') {
          jobOriginExists = true;
        }
      }
      if (!jobOriginExists) {
        this.chartTimes = [{
          id: 5,
          val: this.activeTasq.dateAgoValue + 30,
          text: 'Job Origin',
          additionalClass: 'chart-time--narrow',
        }, ...this.chartTimes];

        this.signalChartTime = this.activeTasq.dateAgoValue + 30;
      }
    }

    //  console.log(this.$isPreloadingEnabled)
    // console.log('fetch')
    this.isOnlineApp = navigator.onLine;
    workflowModule.setV2ResponseData(null);
    tasqsListModule.setFromNowBack(0);

    tasqProductionDataChartModule.resetProductionDataDefermentLabeling();
    if (this.signalChartTime === 14) {
      this.signalChartTime = 30;
    }

    // console.log(this.signalChartTime);

    tasqsListModule.setSignalChartTime(this.signalChartTime);
    if (predictionID != null) {
      // @ts-ignore
      if (!loadingFromSubPrediction) {
        // @ts-ignore
        tasqsListModule.setActiveTasq(predictionID);
        if (tasqsListModule.activeTasq == null) {
          if (this.tasqListLevel.toLowerCase() !== 'pad') {
            await tasqsListModule.getTasq({
              PredictionID: this.$route.params.id,
            });
          }
        }
      }
    } else if (this.$route.params.id != null && this.$route.params.id != '' && this.$route.query.type == 'id') {
      if (!this.isOnlineApp) {
        tasqsListModule.setActiveTasq(this.$route.params.id);
      } else if (this.tasqListLevel.toLowerCase() !== 'pad') {
        await tasqsListModule.getTasq({
			  PredictionID: this.$route.params.id,
		  });
      }
    } else {
      assetsModule.setActiveProducingTasq(this.$route.params.id);
    }

    if (this.activeTasq !== null && this.activeTasq !== null) {
      this.showDataLoadingFeedback = false;
    }

    sleep(1500).then(() => {
      this.$nextTick(() => {
        this.chartsLoading = false;
      });
    });

    if (!loadingFromSubPrediction) {
      // this.chartsLoading = false;
      //  await workflowModule.getWellHistory(this.activeTasq?.wellName);

      const promises: any[] = [];
      promises.push(tasqSignalsModule.getSignalsForTasq(this.signalChartTime));
      promises.push(tasqProductionDataChartModule.getProductionData(this.signalChartTime));
      // promises.push(tasqProductionDataChartModule.getWellHistory(this.signalChartTime));
      // promises.push();
      Promise.all(promises).then(async () => {
		    // this.isLoadingSignals = false;
        // this.chartsLoading = true;

        // await defermentLabelingModule.getChartLabelingData(this.signalChartTime);
        // this.getPredictionList();

        const tasqMainPromises: any = [];

        tasqMainPromises.push(workflowModule.getWellHistory(this.activeTasq?.wellName));

        if (this.activeTasq != null && (this.currentWellType != 'producing')) {
          tasqMainPromises.push(tasqsListModule.getTasqEventHistoryList(this.activeTasq?.workflowTasqId));
        }

        Promise.all(tasqMainPromises).then(async () => {
          if (this.isOnlineApp) {
            let wellTags = await scheduleModule.getTagsForWell({ node_id: this.activeTasq?.wellName });
            wellTags = wellTags.map((t) => JSON.parse(t).toLowerCase());

            this.tags = [...new Set(wellTags)].sort();

            let availableTags = await scheduleModule.getTagsForWell({ node_id: null });
            availableTags = availableTags.map((t) => JSON.parse(t).toLowerCase());
            this.autoCompleteTags = [...new Set(availableTags)].sort();
            if (!tasqsListModule.enablePreloading) {
              // console.log('clear cache');
              // await localForage.clear();
              // await localForageGlobal.clear();
            }
          }
        });

        this.chartsLoading = false;
        // console.log(tasqSignalsModule.currentSignals)
      }, (err) => {
        console.log('Error:');
        console.log(err);
      });
    }

    //  else {
    //   tasqMainPromises.push(workflowModule.getWellHistory(this.activeTasq?.wellName));
    // }
  }

  async tagsAdded(newTag) {
    this.tags.push(newTag.toLowerCase());

    //  newTags.push(newTag)
    await scheduleModule.addTagsToWell({ node_id: this.activeTasq?.wellName, tags: this.tags });
  }

  async tagsChanged(newTag) {
  //  let newTags = this.tags;

    //  newTags.push(newTag)
    await scheduleModule.addTagsToWell({ node_id: this.activeTasq?.wellName, tags: [newTag.toLowerCase()].concat(this.tags) });
  }

  openAddTag() {
    // @ts-ignore
    document.querySelector('.customUINew input').style.background = 'transparent';
  }

  closeAddTag() {
    // @ts-ignore
    document.querySelector('.customUINew input').style.background = '#495364';
  }

  async tagsRemove(removeT) {
    const index = this.tags.indexOf(removeT);
    if (index > -1) {
      this.tags.splice(index, 1);
    }
    await scheduleModule.addTagsToWell({ node_id: this.activeTasq?.wellName, tags: this.tags });
  }

  async batchReassign() {
	  if (this.respondEnabled) {
      tasqsListModule.setIsBatchReassign(true);
      accountModule.getReassignmentList({ useExisting: true });
		  this.prepareAction('reassign');
	  }
  }

  async batchWait() {
	  if (this.respondEnabled) {
      tasqsListModule.setIsBatchReassign(true);
		  this.prepareAction('wait');
	  }
  }

  @Watch('$route.params.id', {
    immediate: true,
  })
  async onTasqRouteIdChange(id: string) {
    if (id) {
      await this.fetchTasq();
      const { tab } = this.$route.query;
      if (tab === 'Feedback') {
        this.activeTasqTab = tab;
      }else{
        this.activeTasqTab = 'Feedback'
      }
      // this.getPredictionList();
    } else {
      tasqsListModule.setActiveTasq('');
    }
    this.multiTasqType = '';
  }
}
